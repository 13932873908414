'use client';
import Error from 'next/error';

import {
  AppRouterErrorProps,
  appRouterSsrErrorHandler,
} from '@highlight-run/next/ssr';

export default appRouterSsrErrorHandler(
  ({ error, reset }: AppRouterErrorProps) => {
    if (process.env.NODE_ENV == "development") throw error;

    console.error("Error in root error handler:", error);

    return (
      <Error statusCode={500} />
    );
  },
);
